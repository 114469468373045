import { useLayoutStore } from "./pinia/layout";
import { useAuthFakeStore } from "./pinia/authFake";
import { useAuthStore } from "./pinia/auth";
import { useNotificationStore } from "./pinia/notification";
import { useCarsStore } from "./pinia/cars";
import { useRolesStore } from "./pinia/roles";
import { useCompaniesStore } from "./pinia/companies";
import { useSubscriptionsStore } from "./pinia/subscriptions";
import { useGlobalStore } from "./pinia/global";
import { useTransactionsStore } from "./pinia/transactions";
import { useCarCategoriesStore } from "./pinia/carcategories";
import { useCarGearBoxStore } from "./pinia/gearbox";
import { useUsersStore } from "./pinia/users"
import { useCarKarossStore } from "./pinia/kaross"
import { useCarDrivningStore } from "./pinia/drivning"
import { useCarBrandStore } from "./pinia/carbrands"
import { useCarBrandModelStore } from "./pinia/carbrandsModel"
import { useCarBrandVersionStore } from "./pinia/carbrandsVersion"
import { useCarEquipmentsStore } from "./pinia/CarEquipments"
import { useBranchesStore } from "./pinia/branches"
import { useVatStore } from "./pinia/vat"
import { useCreditsStore } from "./pinia/Credits"
import { useCarCommentStore } from "./pinia/comments"
import { useArticlesStore } from "./pinia/articles"
import { useDistirbutorsStore } from "./pinia/distirbutors"
import { useCustomersStore } from "./pinia/customers"
import { useSalesInvoicesStore } from "./pinia/salesInvoices"
import { usePurchaseInvoicesStore } from "./pinia/purchaseInvoices"
import { useInvoiceCommentStore } from "./pinia/invoicingComments"
import {usePurchaseInvoiceCommentStore} from "@/state/pinia/purchaseinvoicingComments";
import { usePaymentMethodsStore } from "./pinia/paymentMethods"
import { useSettingsStore } from "./pinia/settings"
import { usePaymentsStore } from "./pinia/payments"
import { useEconomyStore } from "./pinia/economy"
import { createPinia } from "pinia";

const pinia = createPinia();
export default pinia;

export { useEconomyStore,usePurchaseInvoiceCommentStore,usePurchaseInvoicesStore,usePaymentMethodsStore,usePaymentsStore,useSettingsStore,useInvoiceCommentStore,useSalesInvoicesStore,useCustomersStore,useDistirbutorsStore,useArticlesStore,useCarCommentStore,useCreditsStore,useBranchesStore,useCarEquipmentsStore,useVatStore,useCarBrandVersionStore,useCarBrandModelStore,useCarBrandStore,useCarDrivningStore,useCarKarossStore,useCarGearBoxStore,useUsersStore,useTransactionsStore,useGlobalStore,useCarCategoriesStore,useLayoutStore, useAuthFakeStore, useAuthStore, useNotificationStore,useCarsStore,useRolesStore,useCompaniesStore,useSubscriptionsStore };
