// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore, useCompaniesStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"
import moment from "moment";
import {user_can} from "@/utils";

export const useSubscriptionsStore = defineStore("subscriptions", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 100,
        current_page: 1,
        busy: true,
        filtersSample: {
            start_date: '',
            end_date: '',
            free_trial: '',
            renew: '',
            active: '',
            user_subscription_transactions: {
                status: '',
                payment_method: ''
            },
        },
        start_date: null,
        end_date: null,
        filters: {
            start_date: '',
            end_date: '',
            free_trial: '',
            renew: '',
            active: '',
            user_subscription_transactions: {
                status: '',
                payment_method: ''
            },
        },
        currentItem: {},
        newItemSample: {
            start_date: moment(new Date()).format('YYYY-MM-DD'),
            end_date: moment(new Date()).add(1, 'years').format('YYYY-MM-DD'),
            renew: '1',
            active: '1',
            free_trial: '0',
            company_id: null,
            user_subscription_transactions: [
                {
                    cost: '',
                    status: 'PENDING',
                    payment_method: 'TRANSFER'
                }
            ]
        },
        newItem: {
            start_date: moment(new Date()).format('YYYY-MM-DD'),
            end_date: moment(new Date()).add(1, 'years').format('YYYY-MM-DD'),
            renew: '1',
            active: '1',
            free_trial: '0',
            company_id: null,
            user_subscription_transactions: [
                {
                    cost: '',
                    status: 'PENDING',
                    payment_method: 'TRANSFER'
                }
            ]
        },
        UpdateItem: false,
        sort_column: 'active',
        sort_direction: 'desc'
    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.currentUser;
            return token
        },
    },
    actions: {
        fetchList() {
            user_can('read_subscriptions').then(hasPermission => {
                if (hasPermission) {
                    this.loading = true
                    axios.get(process.env.VUE_APP_API_URL + '/users/auth/subscriptions', {
                        params: {
                            page: this.current_page,
                            per_page: this.per_page,
                            filters: this.filters
                        },
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Bearer ' + this.token,
                        },
                    }).then(res => {
                        this.ItemsList = res.data.result.subscriptions.data
                        this.ItemsInfo = res.data.result.subscriptions

                        this.totalItems = res.data.result.subscriptions.total
                        this.current_page = res.data.result.subscriptions.current_page
                        this.per_page = res.data.result.subscriptions.per_page
                        this.loading = false
                    }).catch(error => {
                        this.loading = false
                        console.log(error)
                        Swal.fire("Error!", error.response.data.message, "error");

                    })
                } else {
                    throw new Error('No permission.');
                }
            })
        },
    }

});
