// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"
import {user_can} from "@/utils";

export const useGlobalStore = defineStore("global", {
        state: () => ({
            adminRoles: [],
            paymentMethods: [],
            allLists: {},
            MyArticles: [],
            SettingsItemsList: [],
        }),
        getters: {
            token: () => {
                const authToken = useAuthStore()
                const token = authToken.currentUser;
                return token
            },
            years() {
                const currentYear = new Date().getFullYear();
                const years = [];

                for (let year = currentYear; year > currentYear - 150; year--) {
                    years.push(year);
                }

                return years;
            },
            numbersList() {
                const listTotal = 50;
                let myList = []

                for (let i = 1; i <= listTotal; i++) {
                    myList.push(i);
                }

                return myList;
            },
        },
        actions: {
            async fetchSettings() {
                this.loading = true
                await axios.get(process.env.VUE_APP_API_URL + '/cors/setting/get-setting', {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    },
                }).then(res => {
                    this.SettingsItemsList = res.data.result.item
                    this.loading = false
                }).catch(error => {
                    this.loading = false
                    console.log(error)
                    Swal.fire("Error!", error.response.data.message, "error");

                })
            },
            async fetchAdminRoles() {
                this.loading = true
                await axios.get(process.env.VUE_APP_API_URL + '/core/common/list/roles', {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    },
                }).then(res => {
                    /*  this.adminRoles = res.data.result.list.map(x=>{
                          return x.id
                      })*/
                    this.adminRoles = res.data.result.list
                }).catch(error => {
                    Swal.fire("Error!", error.response.data.message, "error");

                })
            },
            async fetchPaymentMethods() {
                this.loading = true
                await axios.get(process.env.VUE_APP_API_URL + '/core/common/list/subscription-payment-method', {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    },
                }).then(res => {
                    this.paymentMethods = res.data.result.list.map(x => {
                        return x.id
                    })
                }).catch(error => {
                    Swal.fire("Error!", error.response.data.message, "error");

                })
            },
            async fetchMyArticles() {
                this.loading = true
                await axios.get(process.env.VUE_APP_API_URL + '/core/common/list/my-articles', {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    },
                }).then(res => {
                    this.MyArticles = res.data.result.list
                }).catch(error => {
                    Swal.fire("Error!", error.response.data.message, "error");

                })
            },
            fetchBulkList() {
                let lists = [
                    'my-payment_methods', 'vat-methods-invoicing', 'car-categories', 'car-brands', 'car-models', 'car-versions', 'car-status', 'secondary-fuels', 'primary-fuels',
                    'meter-units', 'efect-units', 'fuel-consumption-mixed-units', 'drive-method', 'gearbox', 'CO2-emission-units', 'weight-units',
                    'my-vat-methods', 'my-users', 'my-creditors', 'equipment', 'car-body', 'cam-belt-replaced', 'service-book', 'my-distributors'

                ]
                if (Object.keys(this.allLists).length <= 0) {
                    axios.get(process.env.VUE_APP_API_URL + '/core/common/list/bulklists', {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Bearer ' + this.token,
                        },
                        params: {
                            lists
                        }
                    }).then(res => {
                        this.allLists = res.data.result.lists
                    }).catch(error => {
                        Swal.fire("Error!", error.message || error.response.data.message, "error");
                    })
                }

            }
        },
    });
