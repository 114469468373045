import {defineStore} from "pinia";
import axios from "axios";
import {user_can} from "@/utils";
import Swal from "sweetalert2";
import {useAuthStore} from "@/state/pinia/auth";
import {useGlobalStore} from "@/state/pinia/global";
import {isNull} from "lodash";
import moment from "moment";
import router from "@/router";

export const useCarsStore = defineStore("cars", {
    state: () => ({
        serverItems: [],
        loading: true,
        NewCar: false,
        totalItems: 0,
        ItemsList: [],
        itemsPerPage: 18,
        currentPage: 1,
        CurrentCar: {},
        apiPlurar: 'cars',
        apiSingle: 'item',
        activeProfileTab: 'car',
        CarServiceBook: [
            {date: '2022-03-23', meters: '2290km', desc: 'heööp wpröds'}
        ],
        ItemsInfo: {},
        per_page: 400,
        current_page: 1,
        filters: {
            registration_number: null
        },
        newItem: {},
        CurrentItemModal: false,
        UpdateItem: false,
    }),
    getters: {
        EquipmentsListGetter: (state) => {
            const globalStore = useGlobalStore()
            const allLists = globalStore.allLists

            if (Object.keys(allLists).length > 0) {

                if (Object.keys(state.CurrentCar).length > 0 && state.CurrentCar.car_equipment.length > 0) {

                    let equipments = state.CurrentCar.car_equipment;
                    let EquipmentsList = allLists['equipment']
                    const EquipmentsIdsMap = equipments.map(permission => {
                        return permission.id;
                    });
                    const filteredEquipmentsList = EquipmentsList.filter(permission =>
                        !EquipmentsIdsMap.includes(permission.id)
                    );
                    return filteredEquipmentsList;
                } else {
                    let EquipmentsList = allLists['equipment']
                    return EquipmentsList
                }
            } else {
                return []
            }

        },
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.currentUser;
            return token
        },

    },
    actions: {
        prepareNewCar() {
            this.activeProfileTab = 'car'
            this.CurrentItemModal = true
            this.NewCar = true
            this.CurrentCar = {
                brand_id: null,
                brand: null,
                car_creditor_installments: {
                    date: null,
                    value: null
                },
                car_equipment: [],
                car_articles: [],
                car_guarantees: {
                    last_done: {value: null, unit: 'KM'},
                    gauge_setting: {value: null, unit: 'KM'}
                },
                car_inspections: {
                    date: null,
                    value: null
                },
                car_specifications: {
                    month_of_manufacture: null,
                    manufacture_year: null,
                    co2_emissions: {value: null, unit: 'G/Km'},
                    efect: {value: null, unit: 'KW'},
                    fuel_consumption_mixed: {value: null, unit: '1/MILE'},
                    max_load: {value: null, unit: 'KG'},
                    max_trailer_weight: {value: null, unit: 'TONE'},
                    total_weight: {value: null, unit: 'TONE'},
                },
                car_model: null,
                car_model_id: null,
                car_services: [],
                car_status: "INCOMING",
                car_version_id: null,
                car_version: null,
                category: {},
                category_id: null,
                chassis_number: null,
                color: null,
                contact_person: {},
                contact_person_id: null,
                extra_price: null,
                first_reg_date: null,
                in_price: null,
                in_price_with_vat: null,
                in_stock_date: null,
                is_new_car: "0",
                meter_setting: {value: null, unit: 'KM'},

                model_year: null,
                out_price: null,
                out_price_with_vat: null,
                registration_number: null,
                vat_method: null,
                vat_method_id: null,
                warehouse_location: null,


            }

        },
        deleteRow(roleId) {
            axios.delete(process.env.VUE_APP_API_URL + '/cors/cars/' + roleId, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                let role = this.ItemsList.findIndex(x => x.id == roleId)
                this.ItemsList.splice(role, 1)
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
            }).catch(error => {
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        CreateItem(car) {
            user_can('write_cars').then(hasPermission => {
                if (hasPermission) {
                    this.UpdateItem = true
                    let self = this
                    let data = JSON.parse(JSON.stringify(car))
                    /*  let car_equipment =  JSON.parse(JSON.stringify(data.car_equipment))
                      data.car_specifications.vdn_established =  data.car_specifications.vdn_established ? '1' : '0'
                      data.is_new_car =  data.is_new_car ? '1' : '0'
                      data.car_equipment = car_equipment.map((x)=>{
                          return x.id
                      })*/
                    return axios.post(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar, data, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Bearer ' + this.token,
                        }
                    }).then(res => {
                        this.UpdateItem = false
                        this.CurrentItemModal = false
                        this.ItemsList.unshift(res.data.result.item)
                        this.NewCar = false
                        router.push('/cars/' + res.data.result.item.id)
                    }).catch(error => {
                        this.UpdateItem = false
                        Swal.fire("Error!", error.response, "error");
                    })
                }
            })

        },
        PutItem() {
            user_can('edit_cars').then(hasPermission => {
                if (hasPermission) {
                    this.UpdateItem = true
                    let itemId = this.CurrentCar.id
                    let data = JSON.parse(JSON.stringify(this.CurrentCar))
                    let car_equipment = JSON.parse(JSON.stringify(data.car_equipment))
                    data.car_specifications !== null ? data.car_specifications.vdn_established = data.car_specifications.vdn_established ? '1' : '0' : '0'
                    data.is_new_car = data.is_new_car ? '1' : '0'
                    !isNull(data.car_creditor_installments) ? delete data.car_creditor_installments.controlled_by : ''
                    !isNull(data.car_creditor_installments) ? delete data.car_creditor_installments.paid_user : ''
                    !isNull(data.car_guarantees) ? delete data.car_guarantees.created_at : ''
                    !isNull(data.car_guarantees) ? delete data.car_guarantees.updated_at : ''
                    data.car_equipment = car_equipment.map((x) => {
                        return x.id
                    })
                    return axios.put(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar + '/' + itemId, data, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Bearer ' + this.token,
                        }
                    }).then(res => {
                        this.UpdateItem = false

                        Swal.fire("Success!", res.data.message, "success");
                    }).catch(error => {
                        this.UpdateItem = false
                        Swal.fire("Error!", error.response.data.message, "error");
                    })
                }
            })

        },
        fetchList() {
            user_can('read_cars').then(hasPermission => {
                if (hasPermission) {
                    this.loading = true
                    return axios.get(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar, {
                        params: {
                            page: this.current_page,
                            per_page: this.per_page,
                            filters: this.filters
                        },
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Bearer ' + this.token,
                        },
                    }).then(res => {
                        this.ItemsList = res.data.result[this.apiPlurar].data
                        this.ItemsInfo = res.data.result[this.apiPlurar]
                        this.totalItems = res.data.result[this.apiPlurar].total
                        this.current_page = res.data.result[this.apiPlurar].current_page
                        this.per_page = res.data.result[this.apiPlurar].per_page
                        this.loading = false
                    }).catch(error => {
                        this.loading = false
                        console.log(error)
                        Swal.fire("Error!", error.response.data.message, "error");

                    })
                }
            })


        },
        fetchItem(e) {
            user_can('read_cars').then(hasPermission => {
                if (hasPermission) {
                    return axios.get(process.env.VUE_APP_API_URL + '/cors/' + this.apiPlurar + '/' + e, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Bearer ' + this.token,
                        }
                    });
                } else {
                    throw new Error('No permission to read creditors.');
                }
            }).then(res => {
                let item = res.data.result[this.apiSingle];
                this.CurrentCar = item;
                isNull(this.CurrentCar.meter_setting) ? this.CurrentCar.meter_setting = {value: null, unit: null} : ''
                isNull(this.CurrentCar.car_inspections) ? this.CurrentCar.car_inspections = {
                    date: null,
                    value: null
                } : ''
                if (isNull(this.CurrentCar.car_specifications)) {
                    this.CurrentCar.car_specifications = {
                        month_of_manufacture: null,
                        manufacture_year: null,
                        co2_emissions: {value: null, unit: 'G/Km'},
                        efect: {value: null, unit: 'KW'},
                        fuel_consumption_mixed: {value: null, unit: '1/MILE'},
                        max_load: {value: null, unit: 'KG'},
                        max_trailer_weight: {value: null, unit: 'TONE'},
                        total_weight: {value: null, unit: 'TONE'},
                    }
                }

                if (isNull(this.CurrentCar.car_guarantees)) {
                    this.CurrentCar.car_guarantees = {
                        last_done: {value: null, unit: 'KM'},
                        gauge_setting: {value: null, unit: 'KM'}
                    }
                }
            }).catch(error => {
                console.log(error);
                Swal.fire("Error!", error.message || error.response.data.message, "error");
            });
        },
    },

});


