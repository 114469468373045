// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore,useSalesInvoicesStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"

export const useCustomersStore = defineStore("customers", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 200,
        current_page: 1,
        filters: {name: ''},
        currentItem: {},
        newItem: {
            is_company:0,
        },
        CurrentItemModal: false,
        UpdateItem: false,
        apiPlurar:'customers',
        apiSingle:'item',
        origin:null

    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.currentUser;
            return token
        },
    },
    actions: {
        CreateNewItem() {
            this.UpdateItem = true
            let data = {...this.newItem}
            data.is_company ? data.is_company = 1 : data.is_company = 0

            axios.post(process.env.VUE_APP_API_URL + '/cors/'+this.apiPlurar, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                this.ItemsList.push(res.data.result[this.apiSingle])
                if(this.origin === 'sellInvoice'){
                    const sellInvoice = useSalesInvoicesStore()
                    sellInvoice.searchedCustomer = res.data.result[this.apiSingle]
                }
                Swal.fire("Success!", res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        PutItem() {
            this.UpdateItem = true
            let itemId = this.currentItem.id
            let data = {...this.currentItem}
            data.is_company ? data.is_company = 1 : data.is_company = 0
            axios.put(process.env.VUE_APP_API_URL + '/cors/'+this.apiPlurar+'/' + itemId, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                Swal.fire("Success!", res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        fetchItem(e) {
            axios.get(process.env.VUE_APP_API_URL + '/cors/'+this.apiPlurar+'/' + e, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                res.data.result[this.apiSingle].is_company = Boolean(parseInt(res.data.result[this.apiSingle].is_company))
                let item = res.data.result[this.apiSingle];
                this.currentItem = item
            }).catch(error => {
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        async fetchList() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/cors/'+this.apiPlurar, {
                params: {
                    page: this.current_page,
                    per_page: this.per_page,
                    filters: this.filters
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
                this.ItemsList = res.data.result[this.apiPlurar].data
                this.ItemsInfo = res.data.result[this.apiPlurar]

                this.totalItems = res.data.result[this.apiPlurar].total
                this.current_page = res.data.result[this.apiPlurar].current_page
                this.per_page = res.data.result[this.apiPlurar].per_page
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");

            })
        },
    },

});
