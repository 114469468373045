// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"
import moment from "moment";
import {user_can} from "@/utils";

export const useSalesInvoicesStore = defineStore("salesInvoices", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 200,
        current_page: 1,
        filters: [],
        currentItem: {},
        newItem: {},
        CurrentItemModal: false,
        UpdateItem: false,
        apiPlurar: 'invoicing/sales_invoices',
        apiSingle: 'item',
        searchedCars: [],
        searchedCustomer: {},
        searchedBranch: null,
        searchedCar: {},
        FetchedPayment: {},
        expired_at: moment().add(7, 'days').format('YYYY-MM-DD'),
        Carfilters: {
            registration_number: null
        },
        paymentsItems: [],
    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.currentUser;
            return token
        },
        seller: () => {
            const authStore = useAuthStore()
            const id = authStore.currentLoggedUser.user.id;
            return id
        },
        costEx: (state) => {
            if (Object.keys(state.currentItem).length > 0) {
                let cost_articles = state.currentItem.sales_invoice_articles
                let total = []
                if (cost_articles.length > 0) {
                    Array.from(cost_articles).forEach(x => {
                        let amount = 0
                        if (x.vmb === '1') {
                            amount = parseFloat(x.amount)
                        } else {
                            amount = x.amount - x.vat
                        }
                        total.push(amount)
                    })

                    let theTotal = total.reduce((a, b) => {
                        return a + parseFloat(b)
                    }, 0)
                    if (!isNaN(theTotal)) {
                        return theTotal
                    } else {
                        return 0
                    }
                } else {
                    return 0
                }
            }
            return 0

        },
        costInc: (state) => {
            if (Object.keys(state.currentItem).length > 0) {
                let cost_articles = state.currentItem.sales_invoice_articles
                if (cost_articles.length > 0) {
                    let Total = cost_articles.reduce((accumulator, current) => {
                        return accumulator + parseFloat(current.amount)
                    }, 0)

                    if (!isNaN(Total)) {
                        return Total
                    } else {
                        return 0
                    }
                } else {
                    return 0
                }

            } else {
                return 0
            }


        },
        invoiceTotalEx: (state) => {
            if (Object.keys(state.currentItem).length > 0) {
                let regester_fee = state.currentItem.registration_fees !== null ? parseFloat(state.currentItem.registration_fees) : 0
                let carPrice = parseFloat(state.currentItem.car.out_price)
                let RegesterationFee = parseFloat(regester_fee)
                let discount = state.currentItem.discount_value !== null ? parseFloat(state.currentItem.discount_value) : 0
                let total = (carPrice + RegesterationFee + state.costEx) - discount
                return total
            } else {
                return 0
            }
        },
        invoiceTotalInc: (state) => {
            if (Object.keys(state.currentItem).length > 0) {
                let regester_fee = state.currentItem.registration_fees !== null ? parseFloat(state.currentItem.registration_fees) : 0
                let carPrice = parseFloat(state.currentItem.car.out_price_with_vat)
                let RegesterationFee = parseFloat(regester_fee)
                let discount = state.currentItem.discount_value !== null ? parseFloat(state.currentItem.discount_value) : 0
                let total = (carPrice + RegesterationFee + state.costInc) - discount
                return total
            } else {
                return 0
            }
        },
        totalPayments: (state) => {
            if (Object.keys(state.paymentsItems).length > 0) {
                let total = 0
                state.paymentsItems.forEach(x => {
                    let totalX = x.sales_invoice_payment_details.reduce((a, b) => {
                        return a + parseFloat(b.amount)
                    }, 0)

                    total += totalX
                })
                return total

            } else {
                return 0
            }
        },
    },
    actions: {
        async CancelPayment(itemId, index, e) {
            let endPoint = '/invoicing/payments/cancel'
            if (!e) {
                endPoint = '/invoicing/payments/credit'
            }
            await axios.put(process.env.VUE_APP_API_URL + endPoint + '/' + itemId, {}, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                }
            }).then(res => {
                this.UpdateItem = false;
                // this.paymentsItems[index].fortnox_sales_invoice_payment_id = res.data.result.fortnox_sales_invoice_payment_id
                Swal.fire("Success!", "Tack!", "success");
            }).catch(error=>{
                this.UpdateItem = false;
                Swal.fire("Error!", error.response.data.message || "Failed to send payment to FortNox", "error");
            })
        },
        async SendPaymentToFortNox(itemId, index) {
            try {
                await axios.post(process.env.VUE_APP_API_URL + '/cors/fortnox/create-salesInvoice', {
                    id: itemId
                }, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }).then(res => {
                    this.UpdateItem = false;
                    this.paymentsItems[index].fortnox_sales_invoice_payment_id = res.data.result.fortnox_sales_invoice_payment_id
                    Swal.fire("Success!", "Payment sent to FortNox successfully", "success");
                })

            } catch (error) {
                this.UpdateItem = false;
                Swal.fire("Error!", error.response.data.message || "Failed to send payment to FortNox", "error");
                throw error; // Re-throw the error to be caught by the caller
            }
        },
        DeleteItem(i, itemId) {
            user_can('delete_purchase_invoices').then(hasPermission => {
                if (hasPermission) {
                    return axios.delete(process.env.VUE_APP_API_URL + '/' + this.apiPlurar + '/' + itemId, {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'Authorization': 'Bearer ' + this.token,
                            }
                        }
                    ).then(res => {
                        this.ItemsList.splice(i, 1)
                        Swal.fire("Success!", res.data.message, "success");
                        this.fetchList()
                    }).catch(error => {
                        this.UpdateItem = false
                        Swal.fire("Error!", error.response.data.message, "error");
                    })
                }
            })

        },
        searchForCar(e) {
            if (this.Carfilters.registration_number !== null && this.Carfilters.registration_number !== "") {
                this.UpdateItem = true
                axios.get(process.env.VUE_APP_API_URL + '/cors/cars', {
                        params: {
                            page: 1,
                            per_page: 10000,
                            filters: this.Carfilters
                        },
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Bearer ' + this.token,
                        }
                    }
                ).then(res => {
                    this.UpdateItem = false
                    let carsData = res.data.result.cars.data

                    if (carsData.length > 0) {
                        Array.from(carsData).forEach(x => {
                            this.currentItem.sales_invoice_swaps.unshift({
                                car: x,
                                car_swap_price: x.in_price,
                                car_id: x.id
                            })
                        })
                    }

                }).catch(error => {
                    this.UpdateItem = false
                    console.log(error)
                    Swal.fire("Error!", error.response, "error");
                })
            }


        },
        CreateNewItem() {
            this.UpdateItem = true
            let data = {
                customer_id: Object.keys(this.searchedCustomer).length > 0 ? this.searchedCustomer.id : null,
                to_branch_id: this.searchedBranch !== null ? this.searchedBranch.id : null,
                car_id: this.searchedCar.id,
                seller_id: this.seller,
                is_offer: 0,
                expired_at: this.expired_at,
            }
            axios.post(process.env.VUE_APP_API_URL + '/' + this.apiPlurar, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                this.ItemsList.unshift(res.data.result[this.apiSingle])
                this.currentItem = res.data.result[this.apiSingle]
                this.CurrentItemModal = true
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        PutItem() {
            this.UpdateItem = true
            let itemId = this.currentItem.id
            let data = {...this.currentItem}
            data.is_company ? data.is_company = 1 : data.is_company = 0
            data.accepted_condition ? data.accepted_condition = 1 : data.accepted_condition = 0
            axios.put(process.env.VUE_APP_API_URL + '/' + this.apiPlurar + '/' + itemId, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                Swal.fire("Success!", res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        fetchItem(e) {
            axios.get(process.env.VUE_APP_API_URL + '/' + this.apiPlurar + '/' + e, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                res.data.result[this.apiSingle].is_company = Boolean(parseInt(res.data.result[this.apiSingle].is_company))
                let item = res.data.result[this.apiSingle];
                this.currentItem = item
                this.fetchPayments(item.id)
            }).catch(error => {
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        async fetchList() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/' + this.apiPlurar, {
                params: {
                    page: this.current_page,
                    per_page: this.per_page,
                    filters: this.filters
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
                this.ItemsList = res.data.result.sales_invoices.data
                this.ItemsInfo = res.data.result.sales_invoices

                this.totalItems = res.data.result.sales_invoices.total
                this.current_page = res.data.result.sales_invoices.current_page
                this.per_page = res.data.result.sales_invoices.per_page
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");

            })
        },
        async generateFinalRegulation($t) {
            let items = []
            let carRegulation = {
                article_number: this.currentItem.car.registration_number,
                name: this.currentItem.car.model_year + ': ' + this.currentItem.car.car_model_id + ' ' + this.currentItem.car.chassis_number,
                quantity: '1',
                vat_percent: this.currentItem.car.vat_method.value,
                ex_price: this.currentItem.car.out_price,
                amount: (parseFloat(this.currentItem.car.out_price) * parseFloat(this.currentItem.car.vat_method.value)) / 100 + parseFloat(this.currentItem.car.out_price),
                inc_price: (parseFloat(this.currentItem.car.out_price) * parseFloat(this.currentItem.car.vat_method.value)) / 100 + parseFloat(this.currentItem.car.out_price)

            }
            items.push(carRegulation)
            if (this.currentItem.sales_invoice_articles.length > 0) {
                this.currentItem.sales_invoice_articles.forEach((x) => {
                    items.push(
                        {
                            article_number: x.article_number,
                            name: x.name,
                            quantity: x.quantity,
                            vat_percent: x.vat_percent,
                            ex_price: x.price,
                            amount: x.amount,
                            inc_price: x.amount - x.vat,
                        }
                    )
                })
            }
            if (this.currentItem.sales_invoice_swaps.length > 0) {
                this.currentItem.sales_invoice_swaps.forEach((x) => {
                    items.push(
                        {
                            article_number: x.car.registration_number,
                            name: x.car.registration_number + ' - ' + x.car.model_year,
                            quantity: 1,
                            vat_percent: x.car.vat_method.value,
                            ex_price: -Math.abs(x.car.in_price),
                            amount: -Math.abs(((x.car.in_price * parseFloat(x.car.vat_method.value)) / 100) + parseFloat(x.car.in_price)),
                            inc_price: -Math.abs(((x.car.in_price * parseFloat(x.car.vat_method.value)) / 100) + parseFloat(x.car.in_price))
                        }
                    )
                })
            }

            if (parseInt(this.currentItem.discount_value) !== 0) {
                items.push(
                    {
                        article_number: 'DISC',
                        name: $t('invoiceCalculater.discount'),
                        quantity: 1,
                        vat_percent: '0',
                        ex_price: -Math.abs(this.currentItem.discount_value),
                        amount: -Math.abs(this.currentItem.discount_value),
                        inc_price: -Math.abs(this.currentItem.discount_value)
                    }
                )
            }
            if (parseInt(this.currentItem.registration_fees) !== 0) {
                items.push(
                    {
                        article_number: 'REGF',
                        name: $t('invoiceCalculater.Registration_fee'),
                        quantity: 1,
                        vat_percent: '0',
                        ex_price: this.currentItem.registration_fees,
                        amount: this.currentItem.registration_fees,
                        inc_price: this.currentItem.registration_fees
                    }
                )
            }
            console.log(items)
            this.paymentsItems[0].sales_invoice_payment_details = items
            this.UpdatePaymentMethod(this.paymentsItems[0].id, this.paymentsItems[0])
        },
        async CreateNewPaymentItem(d, a, $t) {
            try {
                this.UpdateItem = true;
                let data = d;
                const response = await axios.post(process.env.VUE_APP_API_URL + '/invoicing/payments', data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                })
                this.UpdateItem = false;
                this.paymentsItems.unshift(response.data.result.item);
                if (a) {
                    this.generateFinalRegulation($t)
                } else {
                    Swal.fire("Success!", response.data.message, "success");
                }

            } catch (error) {
                this.UpdateItem = false;
                Swal.fire("Error!", error.response.data.message, "error");
            }
        },
        DeletePaymentMethod(index, itemId) {
            axios.delete(process.env.VUE_APP_API_URL + '/invoicing/payments/' + itemId, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.paymentsItems.splice(index, 1)
            }).catch(error => {
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        UpdatePaymentMethod(itemId, data) {

            axios.put(process.env.VUE_APP_API_URL + '/invoicing/payments/' + itemId, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {

                Swal.fire("Success!", res.data.message, "success");
            }).catch(error => {
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        async UpdatePaymentMethod_n(itemId, data) {
            try {
                await axios.put(process.env.VUE_APP_API_URL + '/invoicing/payments/' + itemId, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                });
            } catch (error) {
                Swal.fire("Error!", error.response.data.message || "Failed to update payment method", "error");
                throw error; // Re-throw the error to be caught by the caller
            }
        },
        FetchPaymentItem(itemId, data) {
            axios.get(process.env.VUE_APP_API_URL + '/invoicing/payments/' + itemId, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.FetchedPayment = res.data.result.item
            }).catch(error => {
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        async fetchPayments(invoiceId) {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/invoicing/payments/get-by-sales-invoice/' + invoiceId, {
                params: {
                    page: 1,
                    per_page: 100000,
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
                res.data.result.sales_invoice_payments.length > 0 ?
                    this.paymentsItems = res.data.result.sales_invoice_payments :
                    ''
            }).catch(error => {
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");

            })
        },

    },

});
