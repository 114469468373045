import {createApp} from 'vue'


import App from './App.vue'
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import {registerScrollSpy} from 'vue3-scroll-spy';

import {vMaska} from "maska"
import i18n from "./i18n"

import BootstrapVueNext from 'bootstrap-vue-next'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import "@/assets/scss/app.scss";
import "@vueform/multiselect/themes/default.css"
import "vue-multiselect/dist/vue-multiselect.css"



// PINIA
import pinia from '@/state/pinia'

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';
import helperPlugin from "@/helperPlugin";

import VueHtmlToPaper from 'vue-html-to-paper';

const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css',
        'http://localhost:8080/print.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css'
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
}
import VueHtml2pdf from 'vue-html2pdf'

createApp(App)
    .use(pinia)
    .use(VueHtmlToPaper, options)
    .use(router)
    .use(require('vue-chartist'))
    .use(BootstrapVueNext)
    .use(VueApexCharts)
    .use(vClickOutside)
    .use(i18n)
    .use(registerScrollSpy)
    .directive("maska", vMaska)
    .use(helperPlugin)
    .mount('#app')
