// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"

export const usePaymentsStore = defineStore("payments", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 1000,
        current_page: 1,
        filters: [],
        currentItem: {},
        CurrentItemModal: false,
        UpdateItem: false,

    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.currentUser;
            return token
        },
    },
    actions: {
        CreateNewItem() {
            this.UpdateItem = true
            let data = this.currentItem

            axios.post(process.env.VUE_APP_API_URL + '/invoicing/payments', data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                this.ItemsList.push(res.data.result[this.apiSingle])
                Swal.fire("Success!", res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        PutItem() {
            this.UpdateItem = true
            let itemId = this.currentItem.id
            let data = this.currentItem
            axios.put(process.env.VUE_APP_API_URL + '/invoicing/payments/' + itemId, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                Swal.fire("Success!", res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        async fetchList(invoiceId) {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/invoicing/payments/get-by-sales-invoice/'+invoiceId, {
                params: {
                    page: this.current_page,
                    per_page: this.per_page,
                    filters: this.filters
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
                this.ItemsList = res.data.result.sales_invoice_payments.data
                this.ItemsInfo = res.data.result.sales_invoice_payments
                this.totalItems = res.data.result.sales_invoice_payments.total
                this.current_page = res.data.result.sales_invoice_payments.current_page
                this.per_page = res.data.result.sales_invoice_payments.per_page
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");

            })
        },
    },

});
