// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"
import {user_can} from "@/utils";
import { useI18n } from 'vue-i18n';

export const useBranchesStore = defineStore("branches", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 400,
        current_page: 1,
        filters: [],
        currentItem: {},
        newItem: {},
        CurrentItemModal: false,
        UpdateItem: false,
        apiPlurar: 'branches',
        apiSingle: 'item',


    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.currentUser;
            return token
        },
    },
    actions: {
        CreateNewItem() {
            let self = this
            user_can('write_branches').then(hasPermission => {
                if (hasPermission) {
                    this.UpdateItem = true
                    let data = this.newItem

                    axios.post(process.env.VUE_APP_API_URL + '/cors/branches', data, {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'Authorization': 'Bearer ' + this.token,
                            }
                        }
                    ).then(res => {
                        this.UpdateItem = false
                        this.ItemsList.unshift(res.data.result[this.apiSingle])
                        Swal.fire("Success!", res.data.message, "success");
                    }).catch(error => {
                        this.UpdateItem = false

                        Swal.fire("Error!", error.response.data.message, "error");
                    })
                }else{
                    Swal.fire("Error!", 'You lack the necessary permissions', "error");
                }
            })

        },
        DeleteItem(i,itemId) {
            user_can('delete_branches').then(hasPermission => {
                if (hasPermission) {
                    axios.delete(process.env.VUE_APP_API_URL + '/cors/branches/' + itemId, {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'Authorization': 'Bearer ' + this.token,
                            }
                        }
                    ).then(res => {
                        this.ItemsList.splice(i,1)
                        Swal.fire("Success!", res.data.message, "success");
                        this.fetchList()
                    }).catch(error => {
                        this.UpdateItem = false
                        Swal.fire("Error!", error.response.data.message, "error");
                    })
                }else{
                    Swal.fire("Error!", 'You lack the necessary permissions', "error");
                }
            })

        },
        PutItem() {
            user_can('edit_branches').then(hasPermission => {
                if (hasPermission) {
                    this.UpdateItem = true
                    let itemId = this.currentItem.id
                    let data = this.currentItem
                    axios.put(process.env.VUE_APP_API_URL + '/cors/branches/' + itemId, data, {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'Authorization': 'Bearer ' + this.token,
                            }
                        }
                    ).then(res => {
                        this.UpdateItem = false
                        this.ItemsList.find(x=>x.id === itemId).name = this.currentItem.name
                        Swal.fire("Success!", res.data.message, "success");
                    }).catch(error => {
                        this.UpdateItem = false
                        Swal.fire("Error!", error.response.data.message, "error");
                    })
                }else{
                    Swal.fire("Error!", 'You lack the necessary permissions', "error");
                }
            })

        },
        fetchItem(e) {
            user_can('read_branches').then(hasPermission => {
                if (hasPermission) {
                    axios.get(process.env.VUE_APP_API_URL + '/cors/branches/' + e, {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'Authorization': 'Bearer ' + this.token,
                            }
                        }
                    ).then(res => {
                        let item = res.data.result[this.apiSingle];
                        this.currentItem = item
                    }).catch(error => {
                        console.log(error)
                        Swal.fire("Error!", error.response.data.message, "error");
                    })
                }else{
                    Swal.fire("Error!", 'You lack the necessary permissions', "error");
                }
            })

        },
        async fetchList() {

            user_can('read_branches').then(hasPermission => {
                if (hasPermission) {
                    this.loading = true
                     axios.get(process.env.VUE_APP_API_URL + '/cors/branches', {
                        params: {
                            page: this.current_page,
                            per_page: this.per_page,
                            filters: this.filters
                        },
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Bearer ' + this.token,
                        },
                    }).then(res => {
                        this.ItemsList = res.data.result[this.apiPlurar].data
                        this.ItemsInfo = res.data.result[this.apiPlurar]

                        this.totalItems = res.data.result[this.apiPlurar].total
                        this.current_page = res.data.result[this.apiPlurar].current_page
                        this.per_page = res.data.result[this.apiPlurar].per_page
                        this.loading = false
                    }).catch(error => {
                        this.loading = false
                        console.log(error)
                        Swal.fire("Error!", error.response.data.message, "error");

                    })
                }else{
                    Swal.fire("Error!", 'You lack the necessary permissions', "error");
                }
            })

        },
    },

});
