// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"
import moment from "moment";
import {user_can} from "@/utils";

export const useEconomyStore = defineStore("economy", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        sideMenu: false,
        per_page: 150,
        current_page: 1,
        filters: {
            'sended_to_fortnox': '0',
            'status': '',//CREDIT,PAID,CANCEL
            payment_date: {
                'from': '',
                'to': '',
            }

        },
        currentItem: {},
        newItem: {},
        CurrentItemModal: false,
        UpdateItem: false,
        apiPlurar: 'invoicing/reports/sales/sales-invoice-fortnox',
        apiSingle: 'item',

    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.currentUser;
            return token
        },
        seller: () => {
            const authStore = useAuthStore()
            const id = authStore.currentLoggedUser.user.id;
            return id
        },
    },
    actions: {
        async CancelPayment(itemId, e) {
            let endPoint = '/invoicing/payments/cancel'
            let status = 'CANCEL'
            if (!e) {
                endPoint = '/invoicing/payments/credit'
                status = 'CREDIT'
            }
            this.UpdateItem = true
            let Index = this.ItemsList.data.find(x => x.payment_id === itemId)
            await axios.put(process.env.VUE_APP_API_URL + endPoint + '/' + itemId, {}, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                }
            }).then(res => {
                this.UpdateItem = false;
                Index.status = status
                Swal.fire("Success!", "Tack!", "success");
            }).catch(error => {
                this.UpdateItem = false;
                Swal.fire("Error!", error.response.data.message || "Failed to send payment to FortNox", "error");
            })
        },
        async SendPaymentToFortNox(itemId) {
            let Index = this.ItemsList.data.find(x => x.payment_id === itemId)
            this.UpdateItem = true;
            try {
                await axios.post(process.env.VUE_APP_API_URL + '/cors/fortnox/create-salesInvoice', {
                    id: itemId
                }, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }).then(res => {
                    this.UpdateItem = false;
                    Index.fortnox_sales_invoice_payment_id = res.data.result.fortnox_sales_invoice_payment_id
                    Swal.fire("Success!", "Payment sent to FortNox successfully", "success");
                })

            } catch (error) {
                this.UpdateItem = false;
                Swal.fire("Error!", error.response.data.message || "Failed to send payment to FortNox", "error");
                throw error; // Re-throw the error to be caught by the caller
            }
        },
        async fetchList() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/' + this.apiPlurar, {
                params: {
                    filters: this.filters,
                    page: this.current_page,
                    per_page: this.per_page
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
                this.ItemsList = res.data.result.data
                this.totalItems = res.data.result.sum
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");

            })
        },

    },

});
